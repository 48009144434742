import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "components/Layout/en"

export default function(props) {
  const header = {
    text: "MoonVision Imprint",
    description: "MoonVision Imprint",
  }
  return (
    <Layout header={header} location={props.location}>
      <main class="white-background">
        <Container>
          <Row className="py-4">
            <Col md="8">
              <h1>Imprint</h1>
              <p>
                The MoonVision GmbH
                <br />
                Gesellschaft mit beschränkter Haftung
              </p>
              <p>
                IT-Dienstleistungen
                <br />
                Glockengasse 4, Top 105<br />
                1220 Wien
                <br />
                Tel: +43 (1) 890 12 87
              </p>
              <p>
                E-Mail: office@moonvision.io
                <br />
                Mitglied der Wirtschaftskammer Wien
                <br />
                Firmenbuch: FN 476722 m<br />
                Gerichtsstand: Handelsgericht Wien
                <br />
                UID: ATU 72630548
                <br />
                Informationen gemäß §5 eCommerce-Gesetz WKO
              </p>
              <p>
                Verbraucher haben die Möglichkeit, Beschwerden an die Online
                Streitbeilegungsplattform der EU zu richten:
                http://ec.europa.eu/odr. Sie können allfällige Beschwerde auch
                an die oben angegebene E-Mail-Adresse richten.
              </p>
            </Col>

            <Col md="4" />
          </Row>
        </Container>
      </main>
    </Layout>
  )
}
